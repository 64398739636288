import React, { useMemo, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { GreyCard } from 'components/Card';
import { AutoColumn } from 'components/Column';
import { RowFixed, RowFlat } from 'components/Row';
import CurrencyLogo from 'components/CurrencyLogo';
import { TYPE, StyledInternalLink } from 'theme';
import { formatDollarAmount } from 'utils/numbers';
import Percent from 'components/Percent';
import HoverInlineText from 'components/HoverInlineText';
import { TokenData } from '../../data/balancer/balancerTypes';
import Marquee from "react-fast-marquee";

const CardWrapper = styled(StyledInternalLink)`
    min-width: 190px;
    margin-right: 16px;
    
    :hover {
        cursor: pointer;
        opacity: 0.6;
    }
`;

const FixedContainer = styled(AutoColumn)`
    .marquee .child {
        margin-left: 16px;
    }
`;

const DataCard = ({ tokenData }: { tokenData: TokenData }) => {
    return (
        <CardWrapper to={'tokens/' + tokenData.address}>
            <GreyCard padding="16px">
                <RowFixed>
                    <CurrencyLogo address={tokenData.address} size="32px" />
                    <AutoColumn gap="3px" style={{ marginLeft: '12px' }}>
                        <TYPE.label fontSize="14px">
                            <HoverInlineText text={tokenData.symbol} />
                        </TYPE.label>
                        <RowFlat>
                            <TYPE.label fontSize="14px" mr="6px" lineHeight="16px">
                                {formatDollarAmount(tokenData.priceUSD)}
                            </TYPE.label>
                            <Percent fontSize="14px" value={tokenData.priceUSDChange} />
                        </RowFlat>
                    </AutoColumn>
                </RowFixed>
            </GreyCard>
        </CardWrapper>
    );
};

export default function TopTokenMovers({ tokenDatas }: { tokenDatas: TokenData[] }) {
    const topPriceIncrease = tokenDatas
        .sort((a, b) => {
            return a && b ? (Math.abs(a?.priceUSDChange) > Math.abs(b?.priceUSDChange) ? -1 : 1) : -1;
        })
        .slice(0, Math.min(20, tokenDatas.length));

    return (
        <FixedContainer gap="md">
            <Marquee>
                {topPriceIncrease.map((data) =>
                    data ? <DataCard key={'top-card-token-' + data.address} tokenData={data} /> : null,
                )}
            </Marquee>
        </FixedContainer>
    );
}
