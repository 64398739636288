import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

const Card = styled(Box)<{ width?: string; padding?: string; border?: string; borderRadius?: string }>`
    width: ${({ width }) => width ?? '100%'};
    border-radius: 6px;
    position: relative;
    padding: 1rem;
    padding: ${({ padding }) => padding};
    border: none !important;
    border-radius: ${({ borderRadius }) => borderRadius};
    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 5px !important;
        border: 1px solid transparent;
        background: linear-gradient(
                90deg,
                rgba(112, 0, 255, 0.4) 30%,
                rgba(112, 0, 255, 0.6) 45%,
                rgba(47, 23, 133, 0.3) 60%,
                rgba(47, 23, 133, 0.5) 90%
            )
            border-box;

        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: source-out;
        mask-composite: exclude;
    }
`;
export default Card;

export const CardWithoutBorder = styled(Box)<{
    width?: string;
    padding?: string;
    border?: string;
    borderRadius?: string;
}>`
    width: ${({ width }) => width ?? '100%'};
    border-radius: 6px;
    position: relative;
    padding: 1rem;
    padding: ${({ padding }) => padding};
    border-radius: ${({ borderRadius }) => borderRadius};
`;

export const LightCard = styled(Card)`
    border: 1px solid ${({ theme }) => theme.bg2};
    background-color: ${({ theme }) => theme.bg1};
`;

export const LightGreyCard = styled(Card)`
    background-color: ${({ theme }) => theme.bg3};
`;

export const GreyCard = styled(CardWithoutBorder)`
    background-color: ${({ theme }) => theme.bg2};
`;

export const DarkGreyCard = styled(Card)`
    background-color: ${({ theme }) => theme.bg0};
`;

export const TransparentCard = styled(Card)`
    background-color: transparent;
`;

export const OutlineCard = styled(Card)`
    border: 1px solid ${({ theme }) => theme.bg3};
`;

export const YellowCard = styled(Card)`
    background-color: rgba(243, 132, 30, 0.05);
    color: ${({ theme }) => theme.yellow3};
    font-weight: 500;
`;

export const PinkCard = styled(Card)`
    background-color: rgba(255, 0, 122, 0.03);
    color: ${({ theme }) => theme.primary1};
    font-weight: 500;
`;

export const BlueCard = styled(Card)`
    background-color: ${({ theme }) => theme.primary5};
    color: ${({ theme }) => theme.blue2};
    border-radius: 6px;
    width: fit-content;
`;

export const ScrollableX = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const PinkBadge = styled(Card)`
    width: fit-content;
    border-radius: 8px;
    background: ${({ theme }) => theme.customBg1};
    color: ${({ theme }) => theme.text1};
    padding: 4px 6px;
    font-weight: 400;
`;
