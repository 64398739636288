import React, { useEffect } from 'react';
import { PageWrapper } from 'pages/styled';
import { AutoColumn } from 'components/Column';
import { HideSmall, TYPE } from 'theme';
import TokenTable from 'components/tokens/TokenTable';
import { useSavedTokens } from 'state/user/hooks';
import { TransparentCard } from 'components/Card';
import TopTokenMovers from 'components/tokens/TopTokenMovers';
import { useBalancerTokens } from '../../data/balancer/useTokens';

export default function TokensOverview() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [savedTokens] = useSavedTokens();
    const formattedTokens = useBalancerTokens();
    const watchListTokens = formattedTokens.filter((token) => savedTokens.includes(token.address));
    const formattedTokens2 = formattedTokens.filter(
        (token) =>
            token.address !== '0xe3f1cf60b34686ae93ae4d16f6b435ac872976f0' &&
            token.address !== '0xd216fbb3324214c799d7aa0177585dd79c939955' &&
            token.address !== '0xd12f7a98c0d740e7ec82e8caf94eb79c56d1b623' &&
            token.address !== '0xcd7509b76281223f5b7d3ad5d47f8d7aa5c2b9bf' &&
            token.address !== '0x0942d4584b3f3e72347591d59e4d3d31f8bb7bc8' &&
            token.address !== '0x9042d74bdf78fdb27005c8065f49cfc20ec4296c' &&
            token.address !== '0x9427a2a738affbc5880f0646b5251069c022e525' &&
            token.address !== '0xa47d18b6409bded0cbcc1aacb6036543fb6b2b85' &&
            token.address !== '0xabf26902fd7b624e0db40d31171ea9dddf078351' &&
            token.address !== '0x639a647fbe20b6c8ac19e48e2de44ea792c62c5c' &&
            token.address !== '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d' &&
            token.address !== '0xc9baa8cfdde8e328787e29b4b078abf2dadc2055' &&
            token.address !== '0x80a16016cc4a2e6a2caca8a4a498b1699ff0f844',
    );

    return (
        <PageWrapper>
            <AutoColumn gap="lg">
                <TYPE.main>Your Watchlist</TYPE.main>
                {savedTokens.length > 0 ? (
                    <TokenTable tokenDatas={watchListTokens} />
                ) : (
                    <TransparentCard>
                        <TYPE.main>Saved tokens will appear here</TYPE.main>
                    </TransparentCard>
                )}
                <TransparentCard style={{ paddingTop: '12px' }}>
                    <AutoColumn gap="md">
                        <TYPE.mediumHeader fontSize="16px">Top Movers</TYPE.mediumHeader>
                        <TopTokenMovers tokenDatas={formattedTokens2} />
                    </AutoColumn>
                </TransparentCard>
                <TYPE.main>All Tokens</TYPE.main>
                <TokenTable tokenDatas={formattedTokens2} />
            </AutoColumn>
        </PageWrapper>
    );
}
