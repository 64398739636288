import React from 'react';
import styled from 'styled-components';

const ToggleElement = styled.span<{ isActive?: boolean; isOnSwitch?: boolean }>`
    padding: 0.25rem 0.5rem;
    border-radius: 14px;
    background: ${({ theme, isActive, isOnSwitch }) =>
        isActive ? (isOnSwitch ? theme.primary1 : theme.text4) : 'none'};
    color: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.white : theme.text2) : theme.text3)};
    font-size: 1rem;
    font-weight: 400;

    padding: 0.35rem 0.6rem;
    border-radius: 6px;
    background: ${({ theme, isActive, isOnSwitch }) =>
        isActive ? (isOnSwitch ? theme.primary1 : theme.text4) : 'none'};
    color: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.white : theme.text2) : theme.text2)};
    font-size: 1rem;
    font-weight: ${({ isOnSwitch }) => (isOnSwitch ? '500' : '400')};
    :hover {
        user-select: ${({ isOnSwitch }) => (isOnSwitch ? 'none' : 'initial')};
        background: ${({ theme, isActive, isOnSwitch }) =>
            isActive ? (isOnSwitch ? theme.primary1 : theme.text3) : 'none'};
        color: ${({ theme, isActive, isOnSwitch }) =>
            isActive ? (isOnSwitch ? theme.white : theme.text2) : theme.text3};
    }
`;

const StyledToggle = styled.button<{ isActive?: boolean; activeElement?: boolean }>`
    border-radius: 6px;
    border: none;
    background: ${({ theme }) => theme.bg3};
    display: flex;
    width: fit-content;
    cursor: pointer;
    outline: none;
    padding: 0;
`;

export interface ToggleProps {
    id?: string;
    isActive: boolean;
    toggle: () => void;
}

export default function Toggle({ id, isActive, toggle }: ToggleProps) {
    return (
        <StyledToggle id={id} isActive={isActive} onClick={toggle}>
            <ToggleElement isActive={isActive} isOnSwitch={true}>
                On
            </ToggleElement>
            <ToggleElement isActive={!isActive} isOnSwitch={false}>
                Off
            </ToggleElement>
        </StyledToggle>
    );
}

export const ToggleWrapper = styled.button<{ width?: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width ?? '100%'}
  padding: 1px 0 1px 0;
  background: ${({ theme }) => theme.bg2};
  border-radius: 6px;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 50;
  color: ${({ theme }) => theme.text2};

`;

export const ToggleElementFree = styled.span<{ isActive?: boolean; fontSize?: string }>`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2px 10px;
    border-radius: 6px;
    justify-content: center;
    height: 100%;
    background: ${({ theme, isActive }) => (isActive ? theme.customBg1 : 'none')};
    color: ${({ theme, isActive }) => (isActive ? theme.text1 : theme.text2)};
    font-size: ${({ fontSize }) => fontSize ?? '1rem'};
    font-weight: 600;
    white-space: nowrap;
    :hover {
        user-select: initial;
        color: ${({ theme, isActive }) => (isActive ? theme.text2 : theme.text3)};
    }
    margin-top: 0.5px;
`;
